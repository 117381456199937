#listado_filter {
    display: none;
}

form.delete-button {
    display: inline;
}

.checkbox, .radio {
    margin-top: inherit;
}

 .form-group-inline label {
     display: block;
 }

 .form-group-inline .radio, .form-group-inline .checkbox {
     display: inline-block;
     margin-right: 10px;
 }

.ui-autocomplete .ui-menu-item:hover {
    cursor: pointer;
}

.list-unstyled {
    margin-bottom: 10px;
}

.login-box {
    margin: 5% auto;
}

th.actions {
    width: 30px;
}

.form-addon {
    background-color: #e1e3e8;
    border-radius: 3px;
    margin-bottom: 10px;
    padding: 10px;
}

.select2 {
    width: 100% !important;
}

.truncate-cell {
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.truncate-cell-small {
    max-width: 275px;
}

.tooltip-longtext {
    max-width: 600px;
    text-align: left;
}

.box-footer .btn.pull-right {
    margin-left: 10px;
}

.error-page > .headline {
    margin-top: -20px;
}

.maintenance {
    margin: 0 auto;
    max-width: 600px;
    padding: 0 40px;
    text-align: center;
    width: 100%;
}

.sidebar-menu, .main-sidebar .user-panel, .sidebar-menu > li.header {
    white-space: inherit;
}

ul.ui-autocomplete, div.datepicker.dropdown-menu {
    z-index: 2000 !important;
}

dd, dt {
    margin-bottom: 5px;
}

.copyright {
    text-align: left;
}

.footer-menu {
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: none;
    text-align: right;
}

.footer-menu > li {
    display: inline-block;
}

.footer-menu > li:after {
    content: ' - ';
}

.footer-menu > li:last-child:after {
    content: '';
}

.submenucategories {
    padding: 0 10px 10px 10px;
}

.submenucategories ul{
    list-style: none;
    margin: 0 0 0 15px;
    padding: 0;
}

.submenu ul{
    list-style: none;
    margin: 0;
    padding: 10px;
}

.truncate{
    list-style-position:inside;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncate-label{
    list-style-position:inside;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.treeview li span{
    vertical-align: top;
    max-width: 120px;
    display: inline-block;
}

#work-types .form-group {
    margin-bottom: 0;
}

#visa-modal .modal-body {
    background-color: #ecf0f5;
}

.modal-title, .modal-num {
    padding: 5px;
    background-color: #dff0d8;
}

.table-quality > tbody > tr:nth-of-type(2n+1) {
    background-color: #c2ecda;
}

.table-quality > tbody > tr:hover {
    background-color: #bde7d5;
}

.vertical-middle{vertical-align: middle !important;}

#home-calendar .fc-center h2{font-size: 1.5em; margin: 10px 0 0 0;}
#home-calendar *{outline: none;}
#home-calendar .fc-day-number{font-size: 12px !important;}

.home-list{margin: 0; padding: 0; list-style: none;}
.home-list li{padding: 5px 0; border-bottom: 1px solid #f4f4f4;margin: 0;}
.home-list li:last-child{border: none;}
.home-list li a{color: #222D32;}
.home-list li a:hover{color: #dd4b39;}

.timeline > li > .timeline-item > .timeline-header {
    min-height: 52px;
}

.timeline-body {
    cursor: pointer;
}

.timeline-body-hide {
    max-height: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    content: "";
    background: -webkit-linear-gradient(top,#000 70%, #fff);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
}

.title-header{margin: 30px 0 0 0;}
.title-header h1{margin: 0;}

.form-tab-header {
    background-color: #ddd;
    margin: 0 -10px;
}

.no-padding-top{padding-top: 0;}

/*CUSTOM ADMIN MENU*/
td .fa-visado{background: url('../../img/icons/ico-visado-black.png') no-repeat center center; height: 20px; background-size: 20px 20px; display: block;}
td .fa-normativa{background: url('../../img/icons/ico-normativa-black.png') no-repeat center center; height: 20px; background-size: 20px 20px; display: block;}

/*CUSTOM BOX ICONS*/
.info-box-icon .fa-visado{background: url('../../img/icons/ico-visado-white.png') no-repeat center center; width: 90px; height: 90px; background-size: 45px 45px;}
.info-box-icon .fa-normativa{background: url('../../img/icons/ico-normativa-white.png') no-repeat center center; width: 90px; height: 90px; background-size: 45px 45px;}

/*CUSTOM MENU ICONS*/
li > a > i.fa-visado{background: url('../../img/icons/ico-visado-gray.png') no-repeat center center; height: 14px; background-size: 14px 14px; position: relative; top: 2px;}
li:hover > a > i.fa-visado{background: url('../../img/icons/ico-visado-white.png') no-repeat center center; height: 14px; background-size: 14px 14px; position: relative; top: 2px;}
li.active > a > i.fa-visado{background: url('../../img/icons/ico-visado-white.png') no-repeat center center; height: 14px; background-size: 14px 14px; position: relative; top: 2px;}
li > a > i.fa-normativa{background: url('../../img/icons/ico-normativa-gray.png') no-repeat center center; height: 14px; background-size: 14px 14px; position: relative; top: 2px;}
li:hover > a > i.fa-normativa{background: url('../../img/icons/ico-normativa-white.png') no-repeat center center; height: 14px; background-size: 14px 14px; position: relative; top: 2px;}
li.active > a > i.fa-normativa{background: url('../../img/icons/ico-normativa-white.png') no-repeat center center; height: 14px; background-size: 14px 14px; position: relative; top: 2px;}

/*NOTIFICATIONS*/
.notifications-menu .fa-visado{background: url('../../img/icons/ico-visado-white.png') no-repeat center center; width: 16px; height: 16px; background-size: 16px 16px; box-sizing: border-box; top: 3px; position: relative;}
.notifications-menu:hover .fa-visado{background: url('../../img/icons/ico-visado-white.png') no-repeat center center; width: 16px; height: 16px; background-size: 16px 16px; box-sizing: border-box; top: 3px; position: relative;}

/*TABLE PRICE*/
.table-price{margin: 20px auto; width: 500px; text-align: center; background: #DDD; border: 1px solid #CCC;}
.table-price th, .table-price td{padding: 10px !important; text-align: center !important;}
.table-price th{text-align: left !important;}
.table-price td{text-align: right !important;}
.table-price tr.total{border-top: 1px solid #AAA;}

.field-legend{margin-left: 5px; color: #666;font-size: 12px;}

.table-invoices{width: 100%;}
.table-invoices .buttons{width: 50%; text-align: left; padding-left: 2%; padding-top: 2px; padding-bottom: 2px;}
.table-invoices .buttons a[disabled]{background: transparent;border-color: transparent; color: transparent;}
.table-invoices .types{width: 50%; text-align: right; padding-right: 2%; padding-top: 2px; padding-bottom: 2px;}
.table-invoices .all{padding-top: 5px;}

.nav-tabs-custom>.nav-tabs>li.active>a{background: #017769;color: white;}
.checkbox-inline label{font-weight: normal;}
.bg-green-light{background: #dff0d8 !important;}
tr:nth-child(odd).bg-green-light-tr {
    background: #dff0d8 !important;
}
/*REPORT ON-DEMAND*/
.on-demand{}
.on-demand .title{margin: 0; padding: 0; font-weight: bold; text-align: center; background: #CCC; padding: 5px; font-size: 16px;}
.on-demand table{padding: 0; margin: 0; width: 100%;}
.on-demand table th{padding: 5px; background: #EEE;}
.on-demand table td{padding: 5px; vertical-align: middle;}
.on-demand table td input[type=checkbox]{padding: 0; margin: 0 0 10px 0;}
.on-demand table td.check{width: 1px;}
.on-demand table td.name{}
.on-demand table td.input{max-width: 150px; overflow: hidden;}
.on-demand table td.input .form-group{margin: 0;}

.input-captcha label{cursor: pointer;}

.main-footer{height: 300px;}

.overflow{overflow: hidden;}
.btn-visa-print{margin: 0 10px;}

.margin-professional-activity {
    margin-left: -12px;
}

 /*=========================
  Icons
 ================= */
 /* footer social icons */

 ul.social-network {
     list-style: none;
     display: inline;
     margin-left: 0 !important;
     padding: 0;
 }

 ul.social-network li {
     display: inline;
     margin: 0 5px;
 }
 /* footer social icons */

 .social-network a.icoFacebook:hover {
     background-color: #3B5998;
 }

 .social-network a.icoTwitter:hover {
     background-color: #33ccff;
 }

 .social-network a.icoGoogle:hover {
     background-color: #BD3518;
 }

 .social-network a.icoLinkedin:hover {
     background-color: #007bb7;
 }

 .social-network a.icoFacebook:hover i,
 .social-network a.icoTwitter:hover i,
 .social-network a.icoGoogle:hover i,
 .social-network a.icoLinkedin:hover i {
     color: #fff;
 }

 a.socialIcon:hover,
 .socialHoverClass {
     color: #44BCDD;
 }

 .social-circle li a {
     display: inline-block;
     position: relative;
     margin: 0 auto 0 auto;
     -moz-border-radius: 50%;
     -webkit-border-radius: 50%;
     border-radius: 50%;
     text-align: center;
     width: 50px;
     height: 50px;
     font-size: 20px;
 }

 .social-circle li i {
     margin: 0;
     line-height: 50px;
     text-align: center;
 }

 .social-circle li a:hover i,
 .triggeredHover {
     -moz-transform: rotate(360deg);
     -webkit-transform: rotate(360deg);
     -ms--transform: rotate(360deg);
     transform: rotate(360deg);
     -webkit-transition: all 0.2s;
     -moz-transition: all 0.2s;
     -o-transition: all 0.2s;
     -ms-transition: all 0.2s;
     transition: all 0.2s;
 }

 .social-circle i {
     color: #fff;
     -webkit-transition: all 0.8s;
     -moz-transition: all 0.8s;
     -o-transition: all 0.8s;
     -ms-transition: all 0.8s;
     transition: all 0.8s;
 }

 .social-network a {
     background-color: #D3D3D3;
 }

 .author-image {
     width: 50px;
     height: 50px;
     border-radius: 50%;
     float: left;
 }

 .comment {
     margin-bottom: 45px;
 }

 .author-name {
     float: left;
     margin-left: 15px;
 }

 .author-name>h4 {
     margin: 5px 0px;
 }

 .comment-content {
     clear: both;
     margin-left: 65px;
     font-size: 16px;
     line-height: 1.3em;
 }

.author-time {
    font-size: 11px;
    font-style: italic;
    color: #aaa;
}

.comments-title>span {
    margin-right: 15px;
}

 /* .certificate {
     left: 0;
     bottom: 0;
     position: absolute;
     right: 0;
     width: 100%;
     text-align: center;
 } */

 @media (min-width: 1200px) {
    .pull-right-lg {
        float: right;
    }
    .pull-left-lg {
        float: left;
    }
}

@media (max-width: 767px) {

    .table-price{
        width: 100%;
    }

    .table-responsive .dropdown-menu {
        position: static !important;
    }

    dt {
        margin-bottom: 0;
    }

    .copyright {
        text-align: center;
    }

    .footer-menu {
        text-align: center;
        margin-top: 0.5em;
    }
}

@media (min-width: 768px) {
    .table-responsive {
        overflow: visible;
    }

    .dl-horizontal dt {
        text-overflow: inherit;
        white-space: inherit;
    }
}
